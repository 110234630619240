import * as React from "react"
import { useState } from "react"
import { VisuallyHidden } from "reflexjs"
import { Container, Row, Col } from "react-bootstrap"
import { Formik, useFormik, Form, Field } from "formik"
import axios from "axios"

const portalId = "19931890"
const formId = "7565fb50-8563-417f-b68d-b1f6b2688ebd"

function Block({ subheading, heading, text, buttons, ...props }) {
  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const { handleSubmit, handleChange } = useFormik({
    initialValues: {
      firstname: "",
      company: "",
      mobilphone: "",
      email: "",
      message: "",
    },
    onSubmit: async values => {
      console.log(values)

      setIsLoading(true)
      axios
        .post(`https://desolate-tor-83065.herokuapp.com/`, values)
        .then(function(response) {
          console.log(response)
          setIsLoading(false)
          setIsSent(true)
        })
    },
  })

  return (
    <section py="3|4|6|10" {...props}>
      <div variant="container">
        <div display="grid" col="1|2" gap="8|12|16" alignItems="center">
          <div justifyContent="center" alignItems="center" textAlign="center">
            {heading && (
              <h1
                variant="heading.h1"
                fontWeight="bolder"
                lineHeight="tight"
                alignItems="center"
              >
                {heading}
              </h1>
            )}
            {text && (
              <p variant="text.lead" mt="2">
                {text}
              </p>
            )}
            {buttons}
          </div>
          <Container fluid>
            {isSent ? (
              <p style={{ fontWeight: "bold", color: "green" }}>
                Bilgileriniz alındı Sizlere en kısa sürede dönüş sağlayacağız.
              </p>
            ) : (
              <div>
                <p variant="text.lead" mt="2">
                  Formu Doldurarak bizlere anında mesaj gönderebilirsiniz.
                </p>
                <Formik>
                  <form
                    display="grid"
                    col="1|repeat(2, auto)"
                    gap="4"
                    mt="4"
                    w="full|auto"
                    onSubmit={handleSubmit}
                  >
                    <div>
                      <VisuallyHidden>
                        <label htmlFor="name">Adınız</label>
                      </VisuallyHidden>
                      <input
                        variant="input"
                        type="text"
                        id="firstname"
                        name="firstname"
                        placeholder="Adınız"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <VisuallyHidden>
                        <label htmlFor="company"> Firma Adınız</label>
                      </VisuallyHidden>
                      <input
                        variant="input"
                        type="text"
                        id="company"
                        name="company"
                        placeholder="Firma Adınız"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <VisuallyHidden>
                        <label htmlFor="mobilphone"> Telefon Numaranız</label>
                      </VisuallyHidden>
                      <input
                        variant="input"
                        type="text"
                        id="mobilphone"
                        name="mobilphone"
                        placeholder="Telefon Numaranız"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <VisuallyHidden>
                        <label htmlFor="email">Mail Adresiniz</label>
                      </VisuallyHidden>
                      <input
                        variant="input"
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Mail Adresiniz"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <VisuallyHidden>
                        <label htmlFor="message">Mesajınız</label>
                      </VisuallyHidden>
                      <textarea
                        variant="input"
                        type="textarea"
                        id="message"
                        name="message"
                        placeholder="Mesajınız"
                        h="100"
                        onChange={handleChange}
                      />
                    </div>

                    <button type="submit" variant="button.primary">
                      {isLoading ? "Gönderiliyor…" : "Gönder"}
                    </button>
                  </form>
                </Formik>
              </div>
            )}
          </Container>
        </div>
      </div>
    </section>
  )
}

export default function ContactForm() {
  return (
    <Block
      heading="Bizlere Anında Ulaşabilirsiniz"
      text="Yandaki formu doldurup gönderdiğiniz zaman ekibimiz em kısa sürede sizlerle irtibata geçecektir."
      buttons={
        <button variant="button.primary">
          <a variant="button.success" href="tel:+902125500150" mt="6">
            Telefon ile aramak için tıklayın
          </a>
        </button>
      }
    />
  )
}
